<template>
  <div class="watch-contents-container">
    <div v-for="(item, i) in contents" :key="i" class="watch-contents-container-card">
      <categoryCard
        :content="item"
        :cardType="cardDisplayType"
        @play="playContent"
        @remove="removeContent"
        :showRemove="showRemove"
        :showTitle="showTitle"
      ></categoryCard>
    </div>
  </div>
</template>

<script>
import FirebaseActions from "@/mixins/FirebaseActions";
import Utility from "@/mixins/Utility";
import { eventBus } from "@/eventBus";
import { mapActions, mapGetters } from "vuex";

export default {
  props: ['contents'],
  data() {
    return {
      watchlistContents: null,
      showRemove: true,
      showTitle: false,
      filteredAvailabilities: [],
      pricemodel: [],
      subscriptions: [],
      availabilities: [],
      purchases: [],
      cardDisplayType: null,
    };
  },
  computed: {
    ...mapGetters(["subscriberid", "availabilityList", "subscriptionList", "purchaseList"]),
  },
  watch: {
    subscriptionList(val) {
      if (val) {
        this.subscriptions = val;
      }
    },
    purchaseList(val) {
      if (val) {
        this.purchases = val;
      }
    },
    // watchlistContents(val) {
    //   if (val.length > 0) {
    //     this.fetchCardType();
    //   }
    // }
  },
  created() {
    this.fetchCardType();
    this.watchlistContents = this.contents;
    console.log("watchlistContents", this.watchlistContents);
    //assigning availability list response.
    if (this.availabilityList !== undefined) {
      this.availabilities = this.availabilityList;
    }

    //assigning subscription list response.
    if (this.subscriptionList !== undefined) {
      this.subscriptions = this.subscriptionList;
    }

    //assinging purchase list response.
    if (this.purchaseList !== undefined) {
      this.purchases = this.purchaseList;
    }

    //event for card size check for every screen sizes
    eventBus.$on("re-render-carousel", () => {
      return this.fetchCardType();
    });
  },
  // mounted() {
  //   this.fetchCardType();
  // },
  methods: {
    ...mapActions(["contentDetail"]),
    fetchCardType() {
      if (screen.width <= 425) {
        this.cardDisplayType = {
          cardName: "Slider",
          type: "LANDSCAPE",
          height: "90px",
          width: "165px",
          playButton: { enablePlay: false, width: "40px", height: "40px" },
        };
      } else if (screen.width > 425 && screen.width < 800) {
        this.cardDisplayType = {
          cardName: "Slider",
          type: "LANDSCAPE",
          height: "140px",
          width: "230px",
          playButton: { enablePlay: false, width: "40px", height: "40px" },
        };
      } else {
        this.cardDisplayType = {
          cardName: "Slider",
          type: "LANDSCAPE",
          height: "165px",
          width: "290px",
          playButton: { enablePlay: false, width: "40px", height: "40px" },
        };
      }
    },
    playContent(content) {
      console.log("watchlist content", content);
      //get the content details for the given content.
      this.contentDetail(content.objectid)
        .then((response) => {
          console.log("THIS BLOCK ENTERED");
          if (!response.data.reason) {
            this.checkAvailability(response.data);
          }
        })
        .catch((error) => console.log(error));
    },
    removeContent(data) {
      this.watchlistContents = null;
      // console.log("remove", data);
      this.updateContentWatchlist(data, false).then(() => {
        this.$emit("removedWatchlistContent", true);

      })
      // this.watchlistContents = this.watchlistContents.filter(el => {
      //   return el.objectid !== data.objectid;
      // });
    },
  },
  components: {
    categoryCard: () => import(/* webpackChunkName: "categoryCard" */ "@/components/Templates/categoryCard.vue"),
  },
  mixins: [FirebaseActions, Utility],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "./watchlistGridcards.scss"
</style>
